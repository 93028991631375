import React from "react";
import "styles/globals.css";
import FacebookPixel from "@components/FacebookPixel";
import { createContext, useState, useRef } from "react";
import { QueryClient, QueryClientProvider, Hydrate } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import Script from "next/script";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { UserProvider } from "@auth0/nextjs-auth0/client";
import { FB_PIXEL_ID } from "lib/fpixel";

let ErrorBoundary;
if (process.env.NEXT_PUBLIC_BUGSNAG_API_KEY) {
  Bugsnag.start({
    apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginReact()],
  });
  ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);
}
const renderErrorBoundary = (children) => {
  if (ErrorBoundary) return <ErrorBoundary>{children}</ErrorBoundary>;
  return children;
};

export const OutsetaContext = createContext(null);

// This default export is required in a new `pages/_app.js` file.
export default function MyApp({ Component, pageProps, router: { asPath } }) {
  const [outsetaUserId, setOutsetaUserId] = useState("");
  const [outsetaFirstName, setOutsetaFirstName] = useState("");
  const [outsetaUserCaseList, setOutsetaUserCaseList] = useState("");
  const [outsetaUserPlanId, setOutsetaUserPlanId] = useState("");
  const [outsetaUserPlanType, setOutsetaUserPlanType] = useState("");
  const [outsetaStatus, setOutsetaStatus] = useState("init");
  const outsetaRef = useRef(null);



  // Create new QueryClient instance inside ref or state to ensure no data
  // is shared between different users and requests while still only creating
  // QueryClient once per component lifecycle
  const [queryClient] = useState(() => new QueryClient());

  function addOutsetaUserId(userId) {
    setOutsetaUserId(userId);
  }

  function addOutsetaFirstName(firstName) {
    setOutsetaFirstName(firstName);
  }

  function addOutsetaUserCaseList(caseList) {
    setOutsetaUserCaseList(caseList);
  }

  function addOutsetaUserPlanType(userPlanName) {
    setOutsetaUserPlanType(userPlanName);
  }

  function addOutsetaUserPlanId(userPlanId) {
    setOutsetaUserPlanId(userPlanId);
  }

  function updateOutsetaStatus(status) {
    setOutsetaStatus(status);
  }

  return renderErrorBoundary(
    <>
      {/* Outseta */}
      <Script
        id="outseta-options"
        strategy="beforeInteractive"
        dangerouslySetInnerHTML={{
          // __html: `var o_options = { domain: 'uncovered.outseta.com', tokenStorage: 'local' };`,
          __html: `var o_options = { domain: 'uncovered.outseta.com', tokenStorage: 'local', monitorDom: true, profile: { mode: 'embed', selector: '#outseta-profile-embed'} };`,
        }}
      />
      <Script
        src="https://cdn.outseta.com/outseta.min.js"
        strategy="beforeInteractive"
        data-options="o_options"
      ></Script>
      {/* Google Tag Manager */}
      <Script
        id="gtm-script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-NBL9LPN');`,
        }}
      ></Script>
      {/* Global Site Code Pixel - Facebook Pixel */}
      <Script
        id="fb-pixel-loader"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', ${FB_PIXEL_ID});
            `,
        }}
      />
      <Script
        strategy="afterInteractive"
        id="sprig-loader"
        dangerouslySetInnerHTML={{
          __html: `
              (function(l,e,a,p) {
                if (window.Sprig) return;
                window.Sprig = function(){S._queue.push(arguments)}
                var S = window.Sprig;S.appId = a;S._queue = [];window.UserLeap=S;
                a=l.createElement('script');
                a.async=1;a.src=e+'?id='+S.appId;
                p=l.getElementsByTagName('script')[0];
                p.parentNode.insertBefore(a, p);
              })(document, 'https://cdn.sprig.com/shim.js', 'RRoSL0zNLx');
            `,
        }}
      />
      <Script
        strategy="afterInteractive"
        id="adthrive-loader"
        dangerouslySetInnerHTML={{
          __html: `
          (function (w, d) {
            w.adthrive = w.adthrive || {};
            w.adthrive.cmd = w.adthrive.cmd || [];
            w.adthrive.plugin = 'adthrive-ads-manual';
            w.adthrive.host = 'ads.adthrive.com';
            var s = d.createElement('script');
            s.async = true;
            s.referrerpolicy = 'no-referrer-when-downgrade';
            s.src = 'https://' + w.adthrive.host + '/sites/636e875efb71975355967a2a/ads.min.js?referrer=' + w.encodeURIComponent(w.location.href) + '&cb=' + (Math.floor(Math.random() * 100) + 1);
            var n = d.getElementsByTagName('script')[0];
            n.parentNode.insertBefore(s, n);
            console.log('Adthrive script loaded');
          })(window, document);
          `,
        }}
      />
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
          <UserProvider>
            <OutsetaContext.Provider
              value={{
                outsetaUserCaseList,
                addOutsetaUserCaseList,
                outsetaUserId,
                addOutsetaUserId,
                outsetaFirstName,
                addOutsetaFirstName,
                outsetaUserPlanType,
                addOutsetaUserPlanType,
                outsetaUserPlanId,
                addOutsetaUserPlanId,
                isOutsetaLoading: outsetaStatus !== "ready",
                isPaidSubscriber: outsetaUserPlanId === "rm064J9X",
                updateOutsetaStatus,
                outsetaRef,
              }}
            >
              <FacebookPixel>
                {pageProps?.preview && (
                  <a
                    href={`/api/exit-preview?path=${asPath}`}
                    className="fixed bottom-0 left-0 m-6 border border-solid duration-300 flex-shrink-0 font-semibold inline-flex items-center justify-center px-3 transition bg-yellow-400 border-yellow-400 text-white hover:bg-yellow-500 hover:border-yellow-500 rounded-md z-50"
                  >
                    Exit Preview Mode
                  </a>
                )}
                <Component {...pageProps} />
              </FacebookPixel>
            </OutsetaContext.Provider>
          </UserProvider>
        </Hydrate>
      </QueryClientProvider>
    </>
  );
}
